import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { atoms } from '@recoil/atoms';
import { Input, inputClassNames } from '@ui/Form/Input';
import { Icon } from '@ui/Icon/Icon';

export const WizardStep0 = ({ formId, formik, isFile, toggleDisabled }) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState(null);
  const playlistUUID = useRecoilValue(atoms.playlists.editedPlaylistUUID);
  const isEdit = !!playlistUUID;

  useEffect(() => {
    const { name, url, file } = formik.values;
    toggleDisabled(
      isEmpty(name)
      || (!isFile && isEmpty(url) && isEmpty(file))
    );
  }, [formik.values]);

  const onDrop = useCallback(([file, ...rest]) => {
    formik.setFieldValue('file', file);
    setFileName(file.name);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop });

  return (
    <form
      id={formId}
      onSubmit={formik.handleSubmit}
    >
      <Input
        type="text"
        name="name"
        className="mb-4 w-full"
        value={formik.values.name}
        onChange={formik.handleChange}
        placeholder={t('fields.name')}
      />
      {
        !isFile && !fileName && (
          <Input
            type="url"
            name="url"
            className="mb-4 w-full"
            value={formik.values.url}
            onChange={formik.handleChange}
            placeholder={t('fields.link')}
          />
        )
      }

      {!isEdit && !formik.values.url && (
        <div
          className={`${inputClassNames} text-gray-500 mb-4 relative py-4 pl-5 pr-10 h-{58}`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p className="truncate flex">
                {fileName || t('playlists.uploadFile')}
                <Icon name="clip" className="absolute right-3" />
              </p>
          }
        </div>
      )}

      {isEdit && (
        <Input
          type="url"
          name="customEPG"
          className="mb-4 w-full"
          value={formik.values.customEPG}
          onChange={formik.handleChange}
          placeholder={t('fields.customEPG')}
        />
      )}
    </form>
  );
}
