import React, { useEffect } from 'react';
import styled from '@emotion/styled';

export const Toast = ({ type = 'custom', title = 'Message sent successfully.' }) => {
  const ref = React.createRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      ref.current.remove();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [ref]);

  const ToastImage = {
    success: (
      <svg className="w-5 h-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      </svg>
    ),
    danger: (
      <svg className="w-5 h-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    ),
    custom: (
      <svg className="w-5 h-5 text-blue-600 dark:text-blue-500 rotate-45" aria-hidden="true"
           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
              d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9" />
      </svg>
    ),
  };

return (
  <Wrapper
    ref={ref}
   className="absolute right-10 top-20 z-50 flex items-center w-full max-w-xs p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 dark:bg-gray-800"
       role="alert">
    {ToastImage[type]}
    <div className="pl-4 text-sm font-normal">{title}</div>
  </Wrapper>
  );
};

const Wrapper = styled('div')`
  animation: slideIn 0.5s forwards;
  
  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
