import React, { useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { isNull } from 'lodash';

import { playlists } from '@redux/playlists';
import { account } from '@redux/account';
import { atoms } from '@recoil/atoms';
import { useToast } from '@ui/Toast/ToastContext';
import { useDispatch } from 'utils/useDispatch';

import { PlaylistCardUI } from './PlaylistCardUI';
import Bugsnag from '@bugsnag/js';

export const PlaylistCard = ({ uuid }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const { showToast } = useToast();

  const toggleModal = useSetRecoilState(atoms.playlists.modal);
  const setFilter = useSetRecoilState(atoms.playlists.selectedFilter);
  const setEditedPlaylistUUID = useSetRecoilState(atoms.playlists.editedPlaylistUUID);
  const isActiveUser = useSelector(account.selectors.isActiveUser);
  const makeIsV3PlaylistSelector = useMemo(playlists.selectors.makeIsV3PlaylistSelector, []);
  const isV3Playlist = useSelector(state => makeIsV3PlaylistSelector(state, uuid));
  const makePlaylistSelector = useMemo(playlists.selectors.makePlaylistSelector, []);
  const { name, icon, channels_count, isLoading, source_type, json_path } = useSelector(state => makePlaylistSelector(state, uuid ));

  const outdatedSyncPlaylist = useDispatch(playlists.actions.outdatedSyncPlaylist);
  const syncPlaylist = useDispatch(playlists.actions.syncPlaylist);
  const outdatedDeletePlaylist = useDispatch(playlists.actions.outdatedDeletePlaylist);
  const deletePlaylist = useDispatch(playlists.actions.deletePlaylist);
  const fetchPlaylist = useDispatch(playlists.actions.fetchPlaylistData);
  const fetchChannels = useDispatch(playlists.actions.fetchChannels);
  const fetchCategories = useDispatch(playlists.actions.fetchCategories);
  const checkJobStatus = useDispatch(playlists.actions.checkJobStatus);
  const setLoader = useDispatch(playlists.actions.setLoader);

  const handleEditClick = useCallback(() => {
    toggleModal(true);
    setEditedPlaylistUUID(uuid);
  }, [uuid, toggleModal, setEditedPlaylistUUID]);

  const pollJobStatus = async (jobId) => {
    const interval = setInterval(async () => {
      try {
        const response = await checkJobStatus(jobId);
        const data = response.payload;

        if (data.status === 'completed') {
          clearInterval(interval);
          await fetchPlaylist(uuid);
          fetchChannels(uuid);
          fetchCategories(uuid);
          setLoader({ uuid, isLoading: false });
        } else if (data.status === 'failed' || data.status === 'aborted') {
          clearInterval(interval);
          setLoader({ uuid, isLoading: false });
          console.error('Processing failed');
        }
      } catch (error) {
        clearInterval(interval);
        setLoader({ uuid, isLoading: false });
        console.error('Error checking job status:', error);
        Bugsnag.notify(error);
      }
    }, 2000);
  }

  const handleSyncClick = useCallback(async () => {
    if (!isV3Playlist) {
      return await outdatedSyncPlaylist({ uuid });
    } else {
      const response = await syncPlaylist({ uuid });
      if (response.error && response.payload.status === 404) {
        return showToast({
          type: 'danger',
          title: '404: Playlist link not found',
        });
      }
      await pollJobStatus(response.payload.job_id)
    }
  }, [uuid, isV3Playlist]);

  const handleDeleteClick = useCallback(() => {
    isV3Playlist ? deletePlaylist({ uuid }) : outdatedDeletePlaylist({ uuid });
  }, [uuid, deletePlaylist, isV3Playlist]);

  const handleOnClick = useCallback(() => {
    navigate(`/app/playlists/${uuid}`);
    setFilter(null);
  }, [uuid, navigate, setFilter]);

  const channelsCount = useMemo(() => channels_count ? channels_count : t('playlists.null'), [t, channels_count]);
  const subtitle = useMemo(() =>  `${channelsCount} ${t('playlists.channels')}`,[t, channelsCount]);

  return (
    <PlaylistCardUI
      onClick={handleOnClick}
      title={name}
      subtitle={subtitle}
      techData={isNull(json_path) ? 'old structure' : 'new structure'}
      sourceType={source_type}
      imgSrc={icon}
      isLoading={isLoading}
      isActive={params['*'] === uuid}
      hideActions={!isActiveUser}
      handleEditClick={handleEditClick}
      handleSyncClick={handleSyncClick}
      handleDeleteClick={handleDeleteClick}
    />
  )
}
