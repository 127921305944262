import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { nanoid } from '@reduxjs/toolkit';

import { useDispatch } from 'utils/useDispatch';
import { useI18n } from 'utils/useI18n';
import { atoms } from '@recoil/atoms';
import { playlists } from '@redux/playlists';
import { Alert } from '@ui/Alert/Alert';
import { Button } from '@ui/Button/Button';
import { Modal as MModal } from '@ui/Modal/Modal';

import { WizardStep0 } from './WizardStep0';
import { WizardStep1 } from './WizardStep1';
import { useNavigate } from 'react-router-dom';


export const Modal = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const [isModalOpen, toggleModal] = useRecoilState(atoms.playlists.modal);
  const [playlistUUID, setEditedPlaylistUUID] = useRecoilState(atoms.playlists.editedPlaylistUUID);
  const setPending = useSetRecoilState(atoms.layout.pending);
  const makePlaylistSelector = useMemo(playlists.selectors.makePlaylistSelector, []);
  const playlist = useSelector(state => makePlaylistSelector(state, playlistUUID ));
  const errorStatus = useSelector(playlists.selectors.errorStatus);
  const errorKey = useSelector(playlists.selectors.errorKey);
  const isFile = get(playlist, 'source_type', null);
  const isEdit = !!playlistUUID;
  const makeIsV3PlaylistSelector = useMemo(playlists.selectors.makeIsV3PlaylistSelector, []);
  const isV3Playlist = useSelector(state => makeIsV3PlaylistSelector(state, playlistUUID));

  const [isDisabled, toggleDisabled] = useState(true);

  const clearModal = useDispatch(playlists.actions.clearModal);
  const createPlaylist = useDispatch(playlists.actions.createPlaylist);
  const outdatedEditPlaylist = useDispatch(playlists.actions.outdatedEditPlaylist);
  const editPlaylist = useDispatch(playlists.actions.editPlaylist);
  const fetchChannels = useDispatch(playlists.actions.fetchChannels);
  const fetchCategories = useDispatch(playlists.actions.fetchCategories);

  useEffect(() => {
    if(isEdit) {
      toggleDisabled(false);
    }
  }, [isEdit]);

  useEffect(() => {
    return () => {
      setEditedPlaylistUUID(null);
    }
  }, [setEditedPlaylistUUID]);

  const handleCloseModal = useCallback(() => {
    toggleModal(false);
    setEditedPlaylistUUID(null);
    clearModal();
    setStep(0);
    mainFormFormik.resetForm();
  }, [step, toggleModal, setEditedPlaylistUUID, clearModal]);

  const sendRequest = useCallback(async (formData) => {
    if(isEdit) {
      if (isV3Playlist) {
        await editPlaylist({ uuid: playlistUUID, data: formData });
        await fetchChannels(playlistUUID);
        return await fetchCategories(playlistUUID);

      } else {
        formData.append('_method', 'put');
        return await outdatedEditPlaylist({ uuid: playlistUUID, data: formData});
      }
    }
    else {
      return await createPlaylist({ data: formData});
    }
  }, [isEdit, isV3Playlist, playlistUUID, createPlaylist, editPlaylist]);

  const handleSaveMainData = useCallback(async ({ name, url, file, customEPG }) => {
    if(isEmpty(name)) return null;

    toggleDisabled(true);
    setPending(true);

    const formData = new FormData();
    formData.append('name', name);
    if(get(file, 'name')) {
      formData.append('file', file);
    }
    else {
      formData.append('url', url);
    }
    if(customEPG) {
      formData.append('custom_epg_source', JSON.stringify({
        uuid: nanoid(),
        url: customEPG,
      }));
    }

    await sendRequest(formData).then((response) => {
      if(response.meta.requestStatus === 'fulfilled') {
        setEditedPlaylistUUID(response.payload.uuid);
        isEdit ? handleCloseModal() : setStep(1);
      }
      toggleDisabled(false);
      setPending(false);
    });
  }, [isEdit]);

  const handleSaveMoreData = useCallback(async ({ customEPG, archiveId }, { resetForm }) => {
    let customEPGdata = null;

    if (!isEmpty(customEPG)) {
      customEPGdata = JSON.stringify({
        uuid: nanoid(),
        url: customEPG,
      });
    }
    const data = {
      _method: 'put',
      custom_epg_source: customEPGdata,
      archive_id: archiveId || null,
    };

    if (customEPG || archiveId) {
      await editPlaylist({ uuid: playlistUUID, data });
    }

    resetForm();
    handleCloseModal();
    navigate(`/app/playlists/${playlistUUID}`);
  }, [playlistUUID]);

  const customEPGJson = get(playlist, 'custom_epg_source', '');
  const customEPG = useMemo(() => {
    if (!isEmpty(customEPGJson)) {
      return JSON.parse(customEPGJson).url;
    }
    return null;
  }, [customEPGJson]);

  const mainFormFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: get(playlist, 'name', ''),
      url: get(playlist, 'url', ''),
      customEPG,
      file: null,
    },
    onSubmit: handleSaveMainData
  });

  const additionalFormFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      archiveId: get(playlist, 'archive_id', null),
      customEPG,
    },
    onSubmit: handleSaveMoreData,
  });

  const errorMessage = useI18n(errorKey, t(errorStatus === 404 ? 'playlists.createFileError' : 'playlists.createError'));
  if(!isModalOpen) return null;

  return (
    <MModal
      title={isEdit ? t('playlists.editPlaylist') : t('playlists.addPlaylist')}
      handleCloseModal={handleCloseModal}
      footerButtons={(
        <>
          {step === 0 ? (
            <>
              <Button view="ghost" size="middle" onClick={handleCloseModal}>{t('buttons.close')}</Button>
              <Button
                view="outline"
                size="middle"
                form="mainPlaylistForm"
                isDisabled={isDisabled}
                type="submit"
              >
                {isEdit ? t('buttons.save') : t('buttons.continue')}
              </Button>
            </>
          ) : (
            <Button
              view="outline"
              size="middle"
              form="additionalPlaylistForm"
              disabled={isDisabled}
            >
              {t('buttons.save')}
            </Button>
          )}
        </>
      )}
    >
      { step === 0 && <WizardStep0 formId="mainPlaylistForm" formik={mainFormFormik} isFile={isFile} toggleDisabled={toggleDisabled} />}
      { step === 1 && <WizardStep1 formId="additionalPlaylistForm" formik={additionalFormFormik} /> }
      {!!errorStatus && <Alert type="danger">{errorMessage}</Alert>}
    </MModal>
  );
}
