import React from 'react';

import outerRing from './loader-outer-ring.svg';
import redTriangle from './red-triangle.svg';
import blueTriangle from './blue-triangle.svg';
import yellowTriangle from './yellow-triangle.svg';
import violetTriangle from './violet-triangle.svg';

export const Overlay = ({ className }) => {
  return (
    <div className={`w-full h-full bg-black bg-opacity-50 z-100 ${className}`}>
      <div className="absolute inset-0 m-auto w-72 h-72">
        <img src={outerRing} alt="Outer Ring"
             className="w-full h-full absolute inset-0 m-auto" style={{ animation: 'loaderRing 2500ms linear infinite'}} />
        <img src={redTriangle} alt="Red Triangle"
             className="w-full h-full absolute inset-0 m-auto"
             style={{ animation: 'loaderRed 10s ease-in-out infinite' }}
        />
        <img
          src={blueTriangle} alt="Blue Triangle"
          className="w-full h-full absolute inset-0 m-auto"
          style={{ animation: 'loaderBlue 10s ease-in-out infinite' }}
        />
        <img src={yellowTriangle} alt="Yellow Triangle" className="w-full h-full absolute inset-0 m-auto" style={{ animation: 'loaderYellow 10s linear infinite' }} />
        <img src={violetTriangle} alt="Violet Triangle" className="w-full h-full absolute inset-0 m-auto" style={{ animation: 'loaderViolet 10s ease-in-out infinite' }} />
      </div>
    </div>
  );
}
