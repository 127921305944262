import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Input } from '@ui/Form/Input';
import { Button } from '@ui/Button/Button';
import { account } from '@redux/account';
import { useI18n } from 'utils/useI18n';
import { useDispatch } from 'utils/useDispatch';
import { useSearchParams } from 'react-router-dom';

export const PromoSection = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const hasPromoResponse = useSelector(account.selectors.hasPromo);
  const isPromoValid = useSelector(account.selectors.isPromoValid);

  const i18nKey = useSelector(account.selectors.i18nKey);
  const errorMessage = useI18n(i18nKey, t('plans.promoErrorResponse'));

  const clearNotifications = useDispatch(account.actions.clearNotifications);
  const applyPromoCode = useDispatch(account.actions.applyPromoCode);

  const handleSubmit = useCallback(({ promo }, { resetForm }) => {
    if(promo) {
      applyPromoCode(promo);
      resetForm();
    }
  }, [applyPromoCode]);

  const formik = useFormik({
    initialValues: {
      promo: searchParams.get('promo') || ''
    },
    onSubmit: handleSubmit,
  });

  return (
    <div className="mb-6">
      {
        hasPromoResponse ? (
          <div className="py-2 text-center" onClick={clearNotifications}>
            {isPromoValid ? t('plans.promoSuccessResponse') : `\uD83D\uDE32 ${errorMessage}`}
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit} className="grid grid-cols-6 gap-4 md:gap-1 lg:gap-2 xl:gap-4">
            <Input
              className="col-span-3 lg:col-span-4"
              size="small"
              name="promo"
              value={formik.values.promo}
              onChange={formik.handleChange}
              type="text"
              placeholder={t('plans.promo')}
              autoFocus={!!searchParams.get('promo')}
            />
            <Button className="col-span-3 lg:col-span-2" view="secondary" size="middle" type="submit">{t('buttons.apply')}</Button>
          </form>
        )
      }
    </div>
  );
};
