import React, { createContext, useCallback, useState } from 'react';
import { Toast } from './Toast';

export const ToastContext = createContext();

export const useToast = () => React.useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const showToast = useCallback(({ type, title, text }) => {
    const id = Math.random().toString(36).substr(2, 9);
    setToasts((currentToasts) => [
      ...currentToasts,
      { id, type, title, text },
    ]);

    // Remove the toast after 3 seconds
    setTimeout(() => {
      setToasts((currentToasts) =>
        currentToasts.filter((toast) => toast.id !== id)
      );
    }, 5000);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <div className="toast-container">
        {toasts.map((toast) => (
          <Toast key={toast.id} {...toast} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

