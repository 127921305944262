import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { flattenDeep, values, omit, keyBy, toArray, isNull, first, get } from 'lodash';
import { DateTime } from 'luxon';

import { api } from 'api';
import { combineActions } from 'utils/combineActions';

const initialState = {
  accessToken: null,
  user: {},
  devices: {},
  plans: [],
  isCodeSet: null,
  isPromoValid: null,
  language: null,
  settings: null,
  hasError: false,
  errors: [],
  i18nKey: null,
  responseMessage: null,
};

const actions = {
  login: createAsyncThunk(
    'account/login',
    async (credentials, { rejectWithValue }) => {
      try {
        const response = await api.post('user/auth', credentials);
        localStorage.setItem('accessToken', response.token);
        return response;
      } catch (err) {
        return rejectWithValue(err.data);
      }
    },
  ),
  adminLogin: createAsyncThunk(
    'account/adminLogin',
    async (credentials, { rejectWithValue }) => {
      try {
        const response = await api.post('user/auth/admin', credentials);
        localStorage.setItem('accessToken', response.token);
        return response;
      } catch (err) {
        return rejectWithValue(err.data);
      }
    }
  ),
  register: createAsyncThunk(
    'account/register',
    async (credentials, { rejectWithValue }) => {
      try {
        const response = await api.post('user/register', credentials);
        localStorage.setItem('accessToken', response.token);
        return response;
      } catch (err) {
        return rejectWithValue(err.data);
      }
    }
  ),
  changePassword: createAsyncThunk(
    'account/changePassword',
    async (data, { rejectWithValue }) => {
      try {
        await api.post('user/changePassword', data)
      } catch (err) {
        return rejectWithValue(err.data);
      }
    },
  ),
  forgotPassword: createAsyncThunk(
    'account/forgotPassword',
    async (credentials, { rejectWithValue }) => {
      try {
        return await api.post('user/forgotPassword', credentials);
      } catch (err) {
        return rejectWithValue(err.data);
      }
    }
  ),
  fetchSettings: createAsyncThunk(
    'account/fetchSettings',
    () => api.get('settings'),
  ),
  applyPromoCode: createAsyncThunk(
    'account/applyPromoCode',
    async (code, { rejectWithValue }) => {
      try {
        return await api.post('user/membership/promo', { promo_code: code });
      } catch (err) {
        return rejectWithValue(err.data);
      }
    },
  ),
  fetchUser: createAsyncThunk(
    'account/fetchUser',
    async () => {
      const response = await api.get('/user');
      const devices = keyBy(response.devices, 'device_token');
      localStorage.setItem('user', response);
      localStorage.setItem('devices', devices);
      return { user: response, devices };
    }
  ),
  updateUser: createAsyncThunk(
    'account/updateUser',
    async (data, { rejectWithValue }) => {
      try {
        return await api.post('user', data, { unwrap: true });
      } catch (err) {
        return rejectWithValue(err.data);
      }
    },
  ),
  getSignature: createAsyncThunk(
    'account/getSignature',
    planIDs => api.post('user/membership/signature', {
      plan_ids: planIDs,
    })
  ),
  getFreeKassaURL: createAsyncThunk(
    'account/getFreeKassaURL',
    planIDs => api.post('user/membership/freeKassa/pay', {
      plan_ids: planIDs,
    })
  ),
  savePayPalTransaction: createAsyncThunk(
    'account/savePayPalTransaction',
    async ({ orderId, planIDs, isDemo }) => {
      const response = await api.post('user/membership/paypal/check', {
        orderId, planIDs, isDemo
      });
      localStorage.setItem('user', response);
      return response;
    }
  ),
  fetchPlans: createAsyncThunk(
    'account/fetchPlans',
    () => api.get('payment/plans'),
  ),
  setTVCode: createAsyncThunk(
    'account/setTVCode',
    code => api.post('user/saveTVCode', code),
  ),
  editDevice: createAsyncThunk(
    'account/editDevice',
    ({ id, name }) => api.put(`devices/${id}`, { device_name: name }),
  ),
  deleteDevice: createAsyncThunk(
    'account/deleteDevice',
    token => api.post('user/logout', { device_token: token }).then(() => ({ token })),
  ),
  deleteAccount: createAsyncThunk(
    'account/deleteAccount',
    () => api.delete('user/delete'),
  ),
  sendFailLog: createAsyncThunk(
    'account/sendFailLog',
    ({ data, userId, name, type }) => api.post('logs/fail', {
      name,
      failure_type: type,
      description: data,
      user_id: userId,
    })
  ),
  // setError: createAsyncThunk('account/setError'),
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccessToken: (state, { payload }) => {
      localStorage.setItem('accessToken', payload);
      state.accessToken = payload;
    },
    setLanguage: (state, { payload }) => {
      localStorage.setItem('language', payload);
      state.language = payload;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setError: (state, { payload }) => {
      state.errors = [payload] ;
      state.hasError = true;
    },
    logout: state => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      localStorage.removeItem('devices');
    },
    clearNotifications: state => {
      state.isCodeSet = initialState.isCodeSet;
      state.isPromoValid = initialState.isPromoValid;
    },
    clearErrors: state => {
      state.hasError = false;
      state.errors = initialState.errors;
      state.i18nKey = initialState.i18nKey;
    },
    clear: () => initialState,
  },
  extraReducers: builder =>
    builder
      .addCase(actions.fetchSettings.fulfilled, (state, { payload }) => {
        state.settings = payload;
      })
      .addCase(actions.fetchUser.fulfilled,
        (state, { payload }) => {
          state.user = payload.user;
          state.devices = payload.devices;
        })
      .addCase(actions.updateUser.fulfilled,
        (state, { payload: { data, i18n_key } }) => {
          state.user = data;
          state.i18nKey = i18n_key;
          state.hasError = false;
        })
      .addCase(actions.savePayPalTransaction.fulfilled,
        (state, { payload }) => {
          state.user.included_days = payload.included_days;
          state.user.balance = payload.balance;
          state.user.subscription_expires_at = payload.subscription_expires_at;
        })
      .addCase(actions.setTVCode.fulfilled,
        (state => { state.isCodeSet = true })
      )
      .addCase(actions.setTVCode.rejected,
        (state => { state.isCodeSet = false })
      )
      .addCase(actions.applyPromoCode.fulfilled,
        (state, { payload }) => {
          state.user.included_days = payload.included_days;
          state.user.subscription_expires_at = payload.subscription_expires_at;
          state.isPromoValid = true;
        })
      .addCase(actions.applyPromoCode.rejected,
        (state, { payload }) => {
          state.isPromoValid = false;
          state.i18nKey = get(payload, 'i18n_key', null);
        })
      .addCase(actions.fetchPlans.fulfilled,
        (state, { payload }) => {
          state.plans = payload;
        })
      .addCase(actions.editDevice.fulfilled,
        (state, { payload }) => {
          state.devices[payload.device_token] = payload;
        })
      .addCase(actions.deleteDevice.fulfilled,
        (state, { payload }) => {
          state.devices = omit(state.devices, payload.token);
        })
      .addCase(actions.forgotPassword.fulfilled,
        (state, { payload }) => {
          state.responseMessage = payload.message;
        })
      .addCase(actions.forgotPassword.rejected,
        (state, { payload }) => {
          state.hasError = true;
          state.errors = [payload.message];
        })
      .addMatcher(
        combineActions(
          actions.login.fulfilled,
          actions.register.fulfilled,
          actions.adminLogin.fulfilled
        ),
        (state, { payload }) => {
          state.accessToken = payload.token;
          state.user = payload.user;
        })
      .addMatcher(
        combineActions(
          actions.login.pending,
          actions.register.pending,
          actions.forgotPassword.pending,
          actions.updateUser.pending,
          actions.login.fulfilled,
          actions.register.fulfilled,
          actions.changePassword.fulfilled,
        ),
        (state) => {
          state.hasError = false;
          state.i18nKey = null;
        }
      )
      .addMatcher(
        combineActions(
          actions.login.rejected,
          actions.register.rejected,
          actions.changePassword.rejected,
          actions.updateUser.rejected
        ),
        (state, { payload }) => {
          state.hasError = true;
          state.i18nKey = payload?.i18n_key;
          state.errors = payload?.errors && flattenDeep(values(payload.errors));
        })
});

export const account = {
  reducer: slice.reducer,
  actions: {
    ...slice.actions,
    ...actions,
  },
  selectors: {
    accessToken: createSelector(
      state => state.user.accessToken,
      accessToken => accessToken,
    ),
    isAuthenticated: createSelector(
      state => state.user.accessToken,
      accessToken => !!accessToken,
    ),
    language: createSelector(
      state => state.user.language,
      language => language,
    ),
    user: createSelector(
      state => state.user.user,
      user => user,
    ),
    isAdmin: createSelector(
      state => state.user.user,
      user => user && user.user_role > 1,
    ),
    isActiveUser: createSelector(
      state => state.user.user,
      user => user && user.balance > 0,
    ),
    includedDays: createSelector(
      state => state.user.user,
      user => user && user.balance,
    ),
    balance: createSelector(
      state => state.user.user,
      user => user && user.balance,
    ),
    subscriptionExpiresAt: createSelector(
      state => state.user.user,
      user => user && DateTime.fromMillis(user.subscription_expires_at * 1000).toLocaleString(),
    ),
    settings: createSelector(
      state => state.user.settings,
      settings => settings,
    ),
    features: createSelector(
      state => state.user.settings,
      settings => settings && settings.features,
    ),
    devices: createSelector(
      state => state.user.devices,
      devices => toArray(devices),
    ),
    plans: createSelector(
      state => state.user.plans,
      plans => plans,
    ),
    hasError: createSelector(
      state => state.user.hasError,
      hasError => hasError,
    ),
    errors: createSelector(
      state => state.user.errors,
      errors => errors,
    ),
    firstError: createSelector(
      state => state.user.errors,
      errors => first(errors),
    ),
    i18nKey: createSelector(
      state => state.user.i18nKey,
      i18nKey => i18nKey,
    ),
    responseMessage: createSelector(
      state => state.user.responseMessage,
      responseMessage => responseMessage,
    ),
    isPromoValid: createSelector(
      state => state.user.isPromoValid,
      isPromoValid => isPromoValid,
    ),
    hasPromo: createSelector(
      state => state.user.isPromoValid,
      isPromoValid => !isNull(isPromoValid),
    ),
    isCodeSet: createSelector(
      state => state.user.isCodeSet,
      isCodeSet => isCodeSet,
    ),
  }
};
