import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { account } from '@redux/account';
import { playlists } from '@redux/playlists';
import { Accordion } from '@ui/Accordion/Accordion';
import { useDispatch } from 'utils/useDispatch';

import { SidebarItemUI } from './SidebarItemUI';

export const DevicesList = () => {
  const { t } = useTranslation();
  const { playlistUUID } = useParams();
  const playlistDevices = useSelector(playlists.selectors.playlistDevices);
  const devices = useSelector(account.selectors.devices);

  const changeVisibility = useDispatch(playlists.actions.changeVisibility);

  const activeDevices = [];
  const inactiveDevices = [];
  devices.forEach((device) => {
    const playlistDevice = playlistDevices.find(({ id: playlistDeviceId }) => playlistDeviceId === device.id);
    if (playlistDevice && !playlistDevice.is_visible) {
      inactiveDevices.push(device);
    } else {
      activeDevices.push(device);
    }
  });

  const handleHideDevice = (deviceUUID, isVisible) => {
    changeVisibility({ uuid: playlistUUID, data: { is_visible: isVisible }, deviceUUID });
  }

  return (
    <Accordion
      title={t('navBar.devices')}
      content={
        activeDevices.map(({ id, uuid, device_name, app_version }) => (
          <SidebarItemUI
            key={id}
            title={`${device_name} (v.${app_version})`}
            handleHide={() => handleHideDevice(uuid, false)}
            canHide
          />
        ))
      }
      hiddenContent={
        inactiveDevices.map(({ id, uuid, device_name, app_version }) => (
          <SidebarItemUI
            key={id}
            title={`${device_name} (v.${app_version})`}
            className="text-gray-700"
            isHidden
            handleHide={() => handleHideDevice(uuid, true)}
            canHide
          />
        ))
      }
    />
  )
}
