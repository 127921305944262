import React from 'react';

import { Icon } from '@ui/Icon/Icon';
import { IconButton } from '@ui/Button/IconButton';

export const ChannelCardUI = ({
  className,
  title,
  subtitle,
  thumbnail,
  order,
  isFavorite,
  isEdit,
  isHidden,
  isDisabled,
  handleUpdateFavorite,
  handleHide,
  hideActions
}) => {
  return (
    <div
      className={`flex py-2.5 bg-gray-900 whitespace-nowrap text-sm text-gray-700 rounded-md items-center ${ isEdit ? 'pr-3 pl-1' : 'px-2'} ${className}`}
      title={title}
      data-value={order}
    >
      {
        isEdit && <Icon name="move" />
      }
      {
        !isHidden && !hideActions && (
          <IconButton
            name="star"
            className="mr-2"
            fill={isFavorite ? '#FFC815' : 'transparent'}
            stroke={isFavorite ? '#FFC815' : '#454647'}
            onClick={handleUpdateFavorite}
            isDisabled={isDisabled}
          />
        )
      }
      {thumbnail && (
        <picture className="w-7 h-7 mr-2 bg-white rounded-md relative flex-none">
          <img
            src={thumbnail}
            alt={title}
            className="absolute object-contain inset-0 m-auto overflow-hidden"
            style={{ maxWidth: '90%', maxHeight: '90%'}}
            loading="lazy"
            width="22"
            height="22"
          />
        </picture>
      )}
      <div className="inline-flex flex-col overflow-hidden">
        <span className="truncate text-gray-500">{title}</span>
        {subtitle && <span className="text-gray-300">{subtitle}</span>}
      </div>
      {
        !isEdit && handleHide && !hideActions && (
          <IconButton
            className="scale-90 ml-auto"
            name={isHidden ? 'eye' : 'eyeCrossed'}
            onClick={handleHide}
            isDisabled={isDisabled}
          />
      )}
    </div>
  );
};
