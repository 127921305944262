import React from 'react';
import classNames from 'classnames';

import { Button } from '@ui/Button/Button';
import { IconButton } from '@ui/Button/IconButton';

export const SidebarItemUI = ({
  className,
  title,
  isSelected,
  handleClick,
  handleHide,
  isHidden = false,
  canHide = false,
}) => {
  return (
    <li
      className={classNames(
        { 'border-yellow-400 text-yellow-400': isSelected },
        'flex group mb-2 hover:bg-gray-900 border rounded-md pr-0.5 last:mb-0'
      )}>
      <Button
        size="small"
        title={title}
        truncate
        block
        align="left"
        className={`hover:text-yellow-400 pr-0 ${className}`}
        onClick={handleClick}
      >
        {title}
      </Button>
      {canHide && (
        <IconButton
          className="scale-75 ml-auto text-transparent hover:!text-yellow-400 group-hover:text-gray-500"
          name={isHidden ? 'eye' : 'eyeCrossed'}
          onClick={handleHide}
        />
      )}
    </li>
  )
}
