import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isNumber } from 'lodash';

import { atoms } from '@recoil/atoms';
import { playlists } from '@redux/playlists';
import { account } from '@redux/account';
import { useDispatch } from 'utils/useDispatch';

import { ChannelCardUI } from './ChannelCardUI';

export const ChannelCard = ({ uuid, isDnD }) => {
  const { playlistUUID } = useParams();
  const [isLoading, setLoading] = useState(false);
  const isEdit = useRecoilValue(atoms.playlists.isEdit);
  const makeChannelSelector = useMemo(playlists.selectors.makeChannelSelector, []);
  const { name, favorite, hidden, order, sort_order, thumbnail } = useSelector(state => makeChannelSelector(state, uuid ));
  const isActiveUser = useSelector(account.selectors.isActiveUser);
  const makeIsV3PlaylistSelector = useMemo(playlists.selectors.makeIsV3PlaylistSelector, []);
  const isV3Playlist = useSelector(state => makeIsV3PlaylistSelector(state, playlistUUID));

  const updateFavorite = useDispatch(playlists.actions.updateFavorite);
  const outdatedUpdateFavorite = useDispatch(playlists.actions.outdatedUpdateFavorite);
  const outdatedHideChannel = useDispatch(playlists.actions.outdatedHideChannel);
  const hideChannel = useDispatch(playlists.actions.hideChannel);

  const handleUpdateFavorite = useCallback(async() => {
    setLoading(true);
    if (isV3Playlist) {
      await updateFavorite({ playlistUUID, channelUUID: uuid, favorite: favorite ? 0 : 1 });
    }
    else {
      await outdatedUpdateFavorite({ playlistUUID, channelUUID: uuid, favStatus: !favorite });
    }
    setLoading(false);
  },[favorite, playlistUUID, uuid, updateFavorite]);

  const handleHide = useCallback(async () => {
    setLoading(true);
    if (isV3Playlist) {
      await hideChannel({ playlistUUID, channelUUID: uuid, hidden: hidden ? 0 : 1 });
    } else {
      await outdatedHideChannel({ playlistUUID, channelUUID: uuid, isHidden: hidden })
    }
    setLoading(false);
  }, [isV3Playlist, playlistUUID, uuid, hidden, outdatedHideChannel]);

  return (
    <ChannelCardUI
      className={isDnD ? 'cursor-move h-full': ''}
      title={name}
      thumbnail={thumbnail}
      order={isNumber(order) ? order : sort_order}
      isFavorite={favorite}
      isEdit={isEdit}
      isHidden={hidden}
      isDisabled={isLoading}
      hideActions={!isActiveUser}
      handleHide={handleHide}
      handleUpdateFavorite={handleUpdateFavorite}
    />
  );
};
