import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ui/Icon/Icon';

export const Accordion = ({
  title,
  content,
  hiddenContent,
  isOpenByDefault = false,
  className,
}) => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(isOpenByDefault);
  const [showHidden, setShowHidden] = useState(false);
  const onClick = () => setOpen(!isOpen);

  const contentHeight = isOpen ? '100%' : '0';

  const handleHiddenButtonClick = () => {
    setShowHidden(!showHidden);
  }
  if (!content) return null;

  return (
    <div className={`relative mb-8 ${className}`}>
      <h6 className="mb-0">
        <button
          className={`relative w-full text-left bg-black text-white uppercase md:sticky top-0 ${isOpen ? 'open' : ''}`}
          onClick={onClick}
        >
          <span className="inline-flex pr-6">{title}</span>
          <Icon name="chevron" className={`absolute inset-y-0 my-auto right-0 text-xs ${isOpen ? 'opacity-0' : 'opacity-100'}`} />
          <Icon name="chevronRight" className={`absolute inset-y-0 my-auto right-0 text-xs ${isOpen ? 'opacity-100' : 'opacity-0'}`} />
        </button>
      </h6>
      <div
        style={{ height: contentHeight }}
        className="text-center overflow-hidden -ml-2.5"
      >
        <div className="text-sm leading-normal text-blue-gray-500/80 pt-3">
          <ul>
            {content}
            {showHidden && hiddenContent}
          </ul>
        </div>
        {
          !isEmpty(hiddenContent) && (
            <button
              className="text-xs underline text-white pb-3"
              onClick={handleHiddenButtonClick}
            >{showHidden ? t('buttons.hideHidden') : t('buttons.showHidden')}</button>
          )
        }
      </div>
    </div>
  );
};
