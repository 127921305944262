import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';

import { playlists } from '@redux/playlists';
import { useDispatch } from 'utils/useDispatch';

import { ChannelCard } from './components/ChannelCard';

export const SortableList = ({ channels }) => {
  const { playlistUUID } = useParams();

  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setList(channels.map(channel => ({ ...channel, chosen: true })));
  }, [channels]);

  const makeIsV3PlaylistSelector = useMemo(playlists.selectors.makeIsV3PlaylistSelector, []);
  const isV3Playlist = useSelector(state => makeIsV3PlaylistSelector(state, playlistUUID));

  const outdatedSortChannels = useDispatch(playlists.actions.outdatedSortChannels);
  const reorderChannels = useDispatch(playlists.actions.reorderChannels);

  const handleOnEnd = useCallback(async ({ oldIndex, newIndex }) => {
    if(oldIndex !== newIndex) {
      setLoading(true);
      if (isV3Playlist) {
        await reorderChannels({ playlistUUID, oldIndex: list[oldIndex].sort_order, newIndex: list[newIndex].sort_order })
      } else {
        await outdatedSortChannels({ playlistUUID, oldIndex: list[oldIndex].order, newIndex: list[newIndex].order })
      }
      setLoading(false);
    }
  }, [list, isV3Playlist, setLoading, playlistUUID]);

  return (
    <>
      {isLoading && <div className="fixed w-full h-full bg-black opacity-90 cursor-wait top-0 left-0 z-10" />}
      <ReactSortable
        list={list}
        setList={setList}
        className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7 5xl:grid-cols-8 auto-rows-fr gap-2"
        onEnd={handleOnEnd}
      >
        {list.map(({ uuid }) => <div key={uuid}><ChannelCard uuid={uuid} isDnD  /></div>)}
      </ReactSortable>
    </>
  );
};
