import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';

import { atoms } from '@recoil/atoms';
import { account } from '@redux/account';
import { Overlay } from '@ui/Overlay/Overlay';
import { useDispatch } from 'utils/useDispatch';

import { PaddleProvider } from "./PaddleProvider";
import { Plans } from "./components/Plans";

export const PlansContainer = () => {
  const [order, setBasket] = useState([]);
  const isPending = useRecoilValue(atoms.layout.pending);

  const plans = useSelector(account.selectors.plans);
  const user = useSelector(account.selectors.user);

  const fetchUser = useDispatch(account.actions.fetchUser);
  const fetchPlans = useDispatch(account.actions.fetchPlans);
  const clearNotifications = useDispatch(account.actions.clearNotifications);

  useEffect(() => {
    fetchPlans();
    return () => clearNotifications();
  }, [fetchPlans, clearNotifications]);

  const handlePaddleSuccess = (data) => {
    setBasket([]);
    fetchUser();
    console.log('handlePaddleSuccess', data);
  }

  if(isEmpty(plans)) return null;

  return (
    <PaddleProvider user={user} handleComplete={handlePaddleSuccess}>
      <Plans plans={plans} order={order} setBasket={setBasket} />
      {isPending && <Overlay className="absolute top-0 left-0" />}
    </PaddleProvider>
  );
};
