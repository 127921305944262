import React, { useMemo } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Input, inputClassNames } from '@ui/Form/Input';
import { Icon } from '@ui/Icon/Icon';
import { account } from '@redux/account';

export const WizardStep1 = ({ formId, formik }) => {
  const { t } = useTranslation();
  const settings = useSelector(account.selectors.settings);
  const providers = useMemo(() => get(settings, 'providers', []), [settings]);

  return (
    <form
      id={formId}
      onSubmit={formik.handleSubmit}
    >
      <Input
        type="url"
        name="customEPG"
        className="mb-4 w-full"
        value={formik.values.customEPG}
        onChange={formik.handleChange}
        placeholder={t('fields.customEPG')}
      />

      <div className="relative">
        <select
          className={`appearance-none w-full cursor-pointer ${inputClassNames} py-4 px-5`}
          name="archiveId"
          onChange={formik.handleChange}
          value={formik.values.archiveId}
        >
          <option value="">{t('playlists.selectProvider')}</option>
          {providers.map(provider => <option key={provider.id} value={provider.id}>{provider.name}</option>)}
        </select>
        <Icon name="chevron" className="absolute right-4 top-0 bottom-0 my-auto" />
      </div>
    </form>
  )
}
