import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { isEmpty } from 'lodash';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'


import store from './store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { account } from '@redux/account';
import i18next from 'i18next';

const accessToken = localStorage.getItem('accessToken');
let language = localStorage.getItem('language');
language = isEmpty(language) ? 'en' : language;
store.dispatch(account.actions.setLanguage(language));
i18next.changeLanguage(language);
if(accessToken) {
  store.dispatch(account.actions.setAccessToken(accessToken));
}

const container = document.getElementById('root');
const root = createRoot(container);

Bugsnag.start({
  releaseStage: 'production',
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  enabledReleaseStages: ['production'],
  plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Provider store={store}>
        <RecoilRoot>
          <React.Suspense fallback={<div>Loading...</div>}>
            <App />
          </React.Suspense>
        </RecoilRoot>
      </Provider>
    </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
