import classNames from 'classnames';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { slice, isNumber, isEmpty, includes, first } from 'lodash';

import { atoms } from '@recoil/atoms';
import { account } from '@redux/account';
import { playlists } from '@redux/playlists';
import { Button } from '@ui/Button/Button';
import { useToast } from '@ui/Toast/ToastContext';
import { useDispatch } from 'utils/useDispatch';

import { ChannelCard } from './components/ChannelCard';
import { Sidebar } from './components/Sidebar';
import { SortableList } from './SortablePlaylist';

export const Playlist = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { playlistUUID } = useParams();

  const [filter, setFilter] = useRecoilState(atoms.playlists.selectedFilter);
  const [edit, toggleEdit] = useRecoilState(atoms.playlists.isEdit);
  const [page, setPage] = useState(0);
  const perPage = 30;
  const isHidden = useMemo(() => filter === 'hidden', [filter]);

  const isLoading = useSelector(playlists.selectors.isLoading);
  const keys = useSelector(playlists.selectors.channelsKeys);
  const hasHiddenChannels = useSelector(playlists.selectors.hasHiddenChannels);
  const playlistKeys = useSelector(playlists.selectors.keys);
  const isActiveUser = useSelector(account.selectors.isActiveUser);
  const makeIsV3PlaylistSelector = useMemo(playlists.selectors.makeIsV3PlaylistSelector, []);
  const isV3Playlist = useSelector(state => makeIsV3PlaylistSelector(state, playlistUUID));

  const outdatedFetchFullPlaylist = useDispatch(playlists.actions.outdatedFetchFullPlaylist);
  const fetchPlaylistData = useDispatch(playlists.actions.fetchPlaylistData);
  const fetchChannels = useDispatch(playlists.actions.fetchChannels);
  const fetchCategories = useDispatch(playlists.actions.fetchCategories);
  const clearSelectedPlaylist = useDispatch(playlists.actions.clearSelectedPlaylist);

  const isPlaylistsLoaded = !isEmpty(playlistKeys);

  useEffect(() => {
    if (!isPlaylistsLoaded) return;

    if (!isV3Playlist) {
      outdatedFetchFullPlaylist(playlistUUID);
    }
    else {
      fetchPlaylistData(playlistUUID);
      fetchChannels(playlistUUID);
      fetchCategories(playlistUUID);
    }

    return () => {
      clearSelectedPlaylist();
      toggleEdit(false);
    }
  }, [isPlaylistsLoaded, isV3Playlist, playlistUUID, outdatedFetchFullPlaylist, fetchCategories, fetchChannels]);

  useEffect(() => {
    if(!includes(playlistKeys, playlistUUID) && !isEmpty(playlistKeys)) {
      navigate({ pathname: `/app/playlists/${first(playlistKeys)}` }, { replace: true });
    }
  }, [playlistUUID, playlistKeys, navigate]);

  useEffect(() => {
    if(!hasHiddenChannels && filter === 'hidden') {
      if (isV3Playlist) {
        fetchChannels(playlistUUID);
      } else {
        outdatedFetchFullPlaylist(playlistUUID);
      }
      setFilter(null);
    }
  }, [isV3Playlist, filter, setFilter, hasHiddenChannels])

  const list = useMemo(() => keys.filter((key) => {
    const isHidden = key.hidden === true || key.hidden === 1;

    if(filter === 'hidden') {
      return isHidden;
    }
    if(isNumber(filter)) {
      return key.playlist_group_id === filter && !isHidden;
    }
    return !isHidden;
  }), [keys, filter]);

  const paginateList = slice(list, 0, (page + 1) * perPage);
  const hasMore = useMemo(() => list.length > paginateList.length, [list, paginateList]);
  const handleNextPage = () => setPage(page + 1);

  const handleShowAllChannels = async () => {
    setFilter(null);
    if (isV3Playlist) {
      fetchChannels(playlistUUID);
    } else {
      outdatedFetchFullPlaylist(playlistUUID);
    }
  };

  const handleOpenSortMode = async () => {
    if (!isV3Playlist) {
      return toggleEdit(true);
    }
    if (!isLoading) {
      const response =await fetchChannels(playlistUUID);
      if (response.error) {
        return showToast({
          type: 'danger',
          title: '500: Something went wrong',
        });
      }
      toggleEdit(true);
    }
  }

  if (!isLoading && isEmpty(keys)) return <span className="whitespace-pre-wrap">{t('playlists.emptyPlaylist')}</span>;

  return (
    <div id="playlist-data" className="flex flex-col md:flex-row mb-4 mr-4">
      <Sidebar />
      <div id="playlist-channels" className="flex-auto relative bg-black">
        <nav className="playlist-visibility-categories mb-5 bg-black z-10 sticky top-0">
          <ul className="flex text-sm md:text-base border-b border-solid border-gray-900 py-3 -mt-3 md:mr-3.5">
            <li className="mr-2 md:mr-11">
              <Button
                className={classNames({'text-yellow-400': filter === null})}
                onClick={handleShowAllChannels}
              >{t('playlists.allChannels')}</Button></li>
            {hasHiddenChannels && !edit && (
              <li>
                <Button
                  className={classNames({ 'text-yellow-400': isHidden})}
                  onClick={
                    () => {
                      setFilter('hidden');
                      setPage(0);
                    }
                  }>{t('playlists.hidden')}</Button>
              </li>
            )}

            {isActiveUser && !isHidden && (
              <li className="ml-auto">
                {edit
                  ? <Button onClick={() => toggleEdit(false)}>{t('buttons.save')}</Button>
                  : <Button onClick={handleOpenSortMode}>{isLoading ? t('playlists.loading') : t('buttons.edit')}</Button>
                }
              </li>
            )}
          </ul>
        </nav>

        {isLoading ? <span>{t('playlists.loading')}</span> : (
          edit ? (
            <SortableList channels={list} />
          ) : (
            <InfiniteScroll
              className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7 5xl:grid-cols-8 auto-rows-fr gap-2"
              hasMore={hasMore}
              loader={<h6 key="lorem-ipsum">Loading...</h6>}
              pageStart={0}
              loadMore={handleNextPage}
            >
              {paginateList.map(({ uuid }) => <ChannelCard key={uuid} uuid={uuid} />)}
            </InfiniteScroll>
          )
        )}


      </div>
    </div>
  );
}
