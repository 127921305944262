import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { account } from '@redux/account';
import { playlists } from '@redux/playlists';
import { Accordion } from '@ui/Accordion/Accordion';

import { CategoryButton } from './CategoryButton';

export const CategoriesList = () => {
  const { t } = useTranslation();
  const isActiveUser = useSelector(account.selectors.isActiveUser);
  const playlistCategories = useSelector(playlists.selectors.playlistCategories);

  if(isEmpty(playlistCategories)) return null;
  return (
    <Accordion
      title={t('playlists.categories')}
      isOpenByDefault
      content={
        playlistCategories.map(({ id, title }) => (
          <CategoryButton
            key={id}
            id={id}
            title={title}
            hideActions={isActiveUser}
          />))
      }
    />
  )
}
