import {
  faAngleDoubleRight, faCodeBranch, faHeart, faSync, faBars, faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import React, { memo } from 'react';

import { FaIcon } from './FaIcon';
import * as SVGIcons from './svg';
import { AppleTV, Battery, Check, Eye, EyeCrossed, iPad, iPhone } from './svg/SVG';

export const Icon = memo(({ className, name, fill, stroke, width, height, ...rest }) => {
  if(!name) return null;
  const FaIcons = {
    apple: faApple,
    heart: faHeart,
    codeBranch: faCodeBranch,
    sync: faSync,
    angleDoubleRight: faAngleDoubleRight,
    bars: faBars,
    regularStar,
    pencil: faPencilAlt,
  };

  if(FaIcons[name]) {
    return <FaIcon className={className} icon={FaIcons[name]} {...rest} />;
  }

  const Icons = {
    uiPlus: SVGIcons.Plus,
    uiCollapse: SVGIcons.Collapse,
    bbq: SVGIcons.BBQ,
    star: SVGIcons.Star,
    telegram: SVGIcons.Telegram,
    facebook: SVGIcons.Facebook,
    brandWave: SVGIcons.Wave,
    clip: SVGIcons.Clip,
    chevron: SVGIcons.Chevron,
    chevronRight: SVGIcons.ChevronRight,
    chevronLeft: SVGIcons.ChevronLeft,
    'chevron-xs': SVGIcons.ChevronXS,
    move: SVGIcons.Move,
    tv: SVGIcons.TV,
    devices: SVGIcons.iPhone,
    appleTV: AppleTV,
    iPad: iPad,
    iPhone: iPhone,
    feather: SVGIcons.Feather,
    contacts: SVGIcons.Contacts,
    question: SVGIcons.Question,
    times: SVGIcons.Times,
    plus: SVGIcons.Plus,
    minus: SVGIcons.Minus,
    check: Check,
    battery: Battery,
    eye: Eye,
    eyeCrossed: EyeCrossed,
  };

  const SVGIcon = Icons[name];
  return SVGIcon ? <SVGIcon fill={fill} stroke={stroke} className={className} width={width} height={height} /> : null;
});

Icon.defaultProps = {
  fill: 'currentColor',
  stroke: 'currentColor',
};
