import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import { account } from '@redux/account';

import { CategoriesList, DevicesList } from './SidebarComponents';

export const Sidebar = () => {
  const features = useSelector(account.selectors.features);
  const hasV3 = get(features, 'v3', false);

  return (
    <nav className="w-full md:w-40 -mt-3 -ml-2.5 pt-3.5 pl-2.5 md:mr-6 overflow-y-auto flex-none md:sticky md:top-0">
      <>
        {hasV3 && <DevicesList />}
        <CategoriesList />
      </>
    </nav>
  );
}
